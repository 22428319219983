const sampleAdtA28 =
  'MSH|^~\\&|SENDINGAPPNAME|SENDINGFACILITYNAME|RECEIVINGAPPNAME|RECEIVINGFACILITYNAME|201812250115||ADT^A28|8760323|P|2.4\n' +
  'EVN|A28|201812250115\n' +
  'PID||1340|1340||WUNSCH^PAULA^^SR.||19870219|M||2076-8|76798 CATHARINE MANOR^APT. 860^ABNERTON^LA^32694-0999|GL|(931)424-8376^^^paula.wunsch@example.com~(555)555-5555|323-162-1829||S||PATID1340^0^M10|488-490-3844|27122312^LA\n' +
  'NK1|1|ABDIEL^WIEGAND^G|SIS|||||201812250115\n' +
  'NK1|2|MIREYA^LITTEL^B|EMR\n' +
  'PV1|1|O|1000^2018^01||||856^DAVIS^JEFFREY|97514^BODE^EMMALEE||SUR||-|||\n' +
  'AL1|1||^WHEAT||PRODUCES HIVES~RASH\n' +
  'AL1|2||^PET DANDER\n' +
  'DG1|001|I10|1550|MAL NEO LIVER, PRIMARY|19880501103005|20180421050438||\n' +
  'PR1|2234|M11|831^CODE234||20180814010842\n' +
  'ROL|42^RECORDER^ROLE^ROLE MASTER LIST|AD|CP|MYRTICE^KESSLER^TIANA|20180515170584\n' +
  'GT1|1|3719|ROSAMOND^BALISTRERI^S\n' +
  'IN1|1|218905|315849|AETNA||||70439|\n' +
  'IN2|ID1752173|SSN488-490-3844\n' +
  'ROL|61^RECORDER^ROLE^ROLE MASTER LIST|AD|CP|GENESIS^REYNOLDS^NAPOLEON|20180418070453'

module.exports = sampleAdtA28
